import React from 'react'
import { graphql } from 'gatsby'
import WallpaperCentre from '../components/WallpaperCentre/component.js';
import Helmet from 'react-helmet'
import styled from 'react-emotion'
import Header from '../components/Header'
import Content from '../components/Content'
import Preview from '../components/Preview'
import Footer from '../components/Footer'

const Root = styled.div`
display: flex;
flex-direction: column;
height: 100%;
`

// const getParams = (search = '') => {
//   return search.replace('?', '').split('&').reduce((params, keyValue) => {
//     const [key, value = ''] = keyValue.split('=')
//     if (key && value) {
//       params[key] = value.match(/^\d+$/) ? +value : value
//     }
//     return params
//   }, {})
// }

export default function Contact({ data, location, ...rest }) {
  // const { children, location } = this.props
  // const isPost =
  //         location.pathname !== '/' && !location.pathname.match(/^\/blog\/?$/)
  return (
    <Root>
      <Helmet
        title="lisp-ceo - contact"
        meta={[]}>
        <html lang="en" />
      </Helmet>
      <b>Stuff goes here</b>
      <ul>
      <li></li>
      </ul>
    </Root>
  )
}
